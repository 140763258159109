
::v-deep {
    .v-input--checkbox {
        .v-label {
            font-size: 14px;
        }
    }
}

@media (min-width: 768px) {
    ::v-deep {
        .v-input--checkbox {
            .v-label {
                font-size: 16px;
            }
        }
    }
}
