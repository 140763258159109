
.page-section {
    // min-height: calc(100vh - 300px);
    padding: 50px 0;
    &--first {
        padding-top: 80px;
    }
    &--last {
        padding-bottom: 80px;
    }
    &--sm {
        margin-bottom: 40px;
        &--last {
            margin-bottom: 0 !important;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .page-section {
        padding: 60px 0;
        &--first {
            padding-top: 120px;
        }
        &--last {
            padding-bottom: 120px;
        }
        &--sm {
            margin-bottom: 80px;
        }
    }
}
@media (min-width: 1024px) {
    .page-section {
        padding: 80px 0;
        &--first {
            padding-top: 100px;
        }
        &--last {
            padding-bottom: 160px;
        }
        &--sm {
            margin-bottom: 100px;
        }
    }
}
@media (min-width: 1200px) {
}
