
::v-deep {
    .v-dialog--primary {
        max-width: 1200px;
        border-radius: 0;
        overflow-x: hidden;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }
        &::-webkit-scrollbar-thumb {
            width: 4px;
            height: 4px;
            background-color: var(--v-secondary-lighten4);
            border-radius: 2px;
        }
        &::-webkit-scrollbar-track {
            width: 4px;
            height: 4px;
            background-color: var(--v-grey-lighten5);
            border-radius: 2px;
        }
        &__head {
            flex: 0 0 auto;
            height: 46px;
            display: flex;
            justify-content: flex-end;
            .v-btn.v-btn--icon.v-size--default {
                width: 46px;
                height: 46px !important;
                margin: 0 !important;
                padding: 0 !important;
            }
        }
        &__body {
            flex: 0 1 auto;
            overflow-y: auto;
            overflow-x: hidden;
            &::-webkit-scrollbar {
                width: 4px;
                height: 4px;
            }
            &::-webkit-scrollbar-thumb {
                width: 4px;
                height: 4px;
                background-color: var(--v-primary-base);
                border-radius: 2px;
            }
            &::-webkit-scrollbar-track {
                width: 4px;
                height: 4px;
                background-color: var(--v-grey-lighten5);
                border-radius: 2px;
            }
        }
    }
    @media (min-width: 768px) {
        .v-dialog--primary {
            &__head {
                height: 56px;
                .v-btn.v-btn--icon.v-size--default {
                    width: 56px;
                    height: 56px !important;
                }
            }
        }
    }
}
